<template>
	<b-navbar-nav
		ref="serviceMenuElement"
		class="service-menu"
	>
		<b-nav-item
			v-if="!isLoggedUser && !isReservationConfirmed"
			class="label user-name"
			@click="onLoginClick"
		>
			<i class="bi bi-person-fill nav-item-icon" />
			<span
				id="userAccount"
				class="label"
			>{{ $t("label_login") }}</span>
		</b-nav-item>
		<QPopover
			v-else
			v-model="isPopoverVisible"
			:delay="0"
			manual
			@hidden="logout"
		>
			<template #target>
				<b-nav-item
					class="label user-name"
					@click="openPopOver"
				>
					<i class="bi bi-person-fill nav-item-icon" />
					<span
						id="userAccount"
						class="label"
					>{{ userName }}</span>
				</b-nav-item>
			</template>
			<q-link
				v-if="isLoggedUser && firstName"
				class="account-link"
				href="#"
				@click.prevent.stop="onLogoutClick"
			>
				{{ $t("label_log_out") }}
			</q-link>
		</QPopover>
		<b-nav-item
			v-if="isCartVisible"
			class="cart-link"
			:active="isCartView"
			@click="showCart"
		>
			<span class="cart-link-icon">
				<q-badge
					v-if="itemsInCart"
					variant="success"
					pill
				>{{ itemsInCart }}</q-badge>
				<i
					id="cart-link-icon"
					class="bi bi-cart-fill nav-item-icon"
				/>
			</span>
			<span class="label">{{ $t("label_cart") }}</span>
		</b-nav-item>
		<q-offcanvas
			id="sidebar-cart"
			v-model="showSidebar"
			placement="end"
			:title="$t('label_cart')"
			header-class="text-uppercase"
			footer-class="border-dark offcanvas-footer"
			class="sidebar-cart"
			@close="hideCart"
		>
			<CartSummary
				:show-total="false"
				:show-modal="false"
			/>
			<template #footer>
				<div class="fw-bold total-without-taxes">
					<div class="total-label">
						{{ $t("label_total_wo_taxes") }}
					</div>
					<Price
						class="total-in-cart"
						:value="totalWithoutTaxes"
						:system-id="systemId"
					/>
				</div>
				<q-button
					variant="primary"
					@click="checkoutButtonClicked"
				>
					{{ checkoutButtonLabel }}
				</q-button>
			</template>
		</q-offcanvas>
	</b-navbar-nav>
</template>
<script lang="ts" src="./service-menu.ts"></script>
<style lang="scss" src="./service-menu.scss"></style>
