import PrivacyModal from "@app-components/privacy-modal/privacy-modal.vue";
import usei18n from "@app-i18n/index";
import { PolicyTermType } from "@app-rest/common.interfaces";
import router from "@app-router/index";
import { policyPage } from "@app-router/routes/policyTerms";
import useUserStore, { IPrivacyChoice, logout } from "@app-store/user";
import { QButton, QModal, QModalProps, useModalController } from "@qamf/lighthouse";
import { Component, defineComponent, h, ref, VNode } from "vue";

import { toastSuccess } from "./toasts";

interface IModalOption {
	label: string
	value: any
	variant?: string
}

export type ModalController = ReturnType<typeof useModalController>

let _modalController: ModalController | null = null;

export function initModalUtil(controller: ModalController) {
	_modalController = controller;
}

function createModal(message: string | VNode[], localOptions: QModalProps) {
	return defineComponent({
		setup() {
			return () => h(
				QModal,
				{
					// FIXME: Fix typing on QModalProps
					// Tech Story 71652: [Bowler] Remove any as QModalProps typing
					...localOptions as any
				},
				{
					default: () => message
				}
			);
		}
	});
}

async function launchModal(modalTemplate: Readonly<Component>) {
	if (_modalController === null) return;
	const modal = await _modalController.confirm?.({
		component: modalTemplate
	});
	return modal;
}

function getDefaultOptions(): QModalProps {
	const { translateKey } = usei18n();
	return {
		bodyClass: "",
		cancelTitle: translateKey("label_cancel"),
		cancelVariant: "outline-primary",
		centered: true,
		footerClass: "",
		dialogClass: "",
		modalClass: "modal-utility",
		noCloseOnBackdrop: true,
		noCloseOnEsc: true,
		okTitle: translateKey("label_ok"),
		okVariant: "primary",
		scrollable: true,
		modelValue: true
	};
}
export function modalEmpty(message: string | VNode[], overrides?: QModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	const id = "modal-empty" + Date.now();
	localOptions.id = localOptions.id ?? id;
	localOptions.modalClass = localOptions.modalClass + " modal-empty";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;

	const messageNodes = typeof message === "string"
		? [h("div", message)]
		: message;
	const messageWrapper = h("div", { class: "message-wrapper" }, messageNodes);
	return launchModal(createModal([messageWrapper], localOptions));
}

export function modalError(message: string | VNode[], overrides?: QModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-error";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	return launchModal(createModal(message, localOptions));
}
export function modalWarning(message: string | VNode[], overrides?: QModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-warning";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	return launchModal(createModal(message, localOptions));
}
export async function modalFatal(overrides?: QModalProps) {
	const { translateKey } = usei18n();
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-error";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	localOptions.title = translateKey("common_error_modal_something_went_wrong");
	localOptions.centered = true;
	localOptions.noCloseOnEsc = true;
	localOptions.noCloseOnBackdrop = true;
	const message = [
		h("div",
			[
				h("p", translateKey("common_error_modal_page_does_not_work")),
				h("p", translateKey("common_error_modal_message_please_close_this_window_to_refresh"))
			]
		)
	];
	return launchModal(createModal(message, localOptions));
}
export async function modalCartError(overrides?: QModalProps) {
	const { translateKey } = usei18n();
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-error";
	localOptions.hideHeaderClose = true;
	localOptions.title = translateKey("common_error_modal_something_went_wrong");
	localOptions.centered = true;
	localOptions.noCloseOnEsc = true;
	localOptions.noCloseOnBackdrop = true;
	const message = [
		h("div",
			[
				h("p", translateKey("common_error_modal_page_does_not_work")),
				h("p", translateKey("common_error_modal_message_please_close_this_window_to_start_a_new_reservation"))
			]
		)
	];
	return launchModal(createModal(message, localOptions));
}
export function modalConfirm(message: string | VNode[], overrides?: QModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-confirm";
	return launchModal(createModal(message, localOptions));
}
export function modalWarningConfirm(message: string | VNode[], overrides?: QModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-warning modal-confirm";
	return launchModal(createModal(message, localOptions));
}
export function modalWarningOneButton(message: string | VNode[], overrides?: QModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-warning modal-confirm modal-one-button";
	localOptions.footerClass = localOptions.modalClass + " border-top-0";
	return launchModal(createModal(message, localOptions));
}
export function modalOk(message: string | VNode[], overrides?: QModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-ok";
	return launchModal(createModal(message, localOptions));
}
export function modalOptions(message: string | VNode[], options: IModalOption[], overrides?: QModalProps): Promise<boolean> {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	const id = "modal-with-options-" + Date.now();
	localOptions.id = id;
	localOptions.hideHeaderClose = true;
	localOptions.modalClass = localOptions.modalClass + " modal-utility modal-with-options modal-confirm";
	localOptions.footerClass = "d-none";

	return new Promise(resolve => {
		const messageNodes = typeof message === "string"
			? [h("div", message)]
			: message;
		const messageWrapper = h("div", { class: "message-wrapper" }, messageNodes);
		const optionsWrapper = h(
			"template",
			{ class: "options-wrapper modal-footer", attrs: { options: options.length }, slot: "footer" },
			options.map(option => {
				const btn = h(QButton, {
					variant: option.variant ?? "outline-primary" as any,
					onClick: () => {
						resolve(option.value);
						_modalController?.hide();
					}
				}, () => [option.label]);
				return btn;
			})
		);
		return launchModal(createModal([messageWrapper, optionsWrapper], localOptions)) as any;
	});
}
export function modalPrivacy(companyId: number, centerName: string) {
	const { translateKey } = usei18n();
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts);
	localOptions.modalClass = localOptions.modalClass + " modal-privacy";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	localOptions.title = translateKey("signup_modal_title");

	const appRouter = ref(router);
	const policyRoute = router.resolve({
		name: policyPage,
		params: {
			companyId: appRouter.value.currentRoute.params.companyId,
			systemId: appRouter.value.currentRoute.params.systemId,
			type: PolicyTermType.PrivacyPolicy
		},
		query: appRouter.value.currentRoute.query
	});
	const urlPrivacyCompany = policyRoute.href;

	const message = h(PrivacyModal, {
		privacyLink: urlPrivacyCompany,
		onProceed: async(data: IPrivacyChoice[]) => {
			await useUserStore().completeUserSignup({
				companyId,
				privacyData: {
					privacyChoices: data
				}
			});
			toastSuccess(translateKey("signup_complete", { centerName }));
			_modalController?.hide();
		},
		onDecline: async() => {
			await logout();
			_modalController?.hide();
		}
	});
	return launchModal(createModal([message], localOptions));
}
