import { ICartSummaryRequestBody, ICartSummaryResponse } from "@app-rest/cart.interfaces";
import { useClientBuilder } from "@app-rest/index";

const useRest = useClientBuilder.createRestClient();
useRest().setOption("cacheInMemory", false);

export default function useCartRestClient() {
	const { post, cloneOptions } = useRest();

	function cartSummary(centerId: number, data: ICartSummaryRequestBody) {
		const headers = cloneOptions().headers;
		headers?.set("Content-Type", "application/json");

		return post<ICartSummaryResponse>(`/centers/${centerId}/Cart/CreateSummary`, {
			body: data,
			headers
		});
	}

	return {
		cartSummary
	};
}
