import { IPolicyTerm } from "@app-rest/common.interfaces";
import { IResponseError, useClientBuilder } from "@app-rest/index";
import { ICommonParts, IFunctionalities, IGenericPage, IHomePage, INavigationBar, IOpeningTimes, IPlayersTypes, IReservationPageResponse, IStatusFoodAndBeverageAvailableAt, ISystemConfigurations, ISystemCurrency, ISystemInfo, ITerms, IThankYouPageResponse, ProviderConfiguration } from "@app-rest/systems.interfaces";
import { dateKey, dateTimeKey } from "@app-utilities/dates";
import { SystemConfigurationName } from "@app-utilities/interfaces";

export type PageType = "FOOD AND DRINK" | "GAMES AND EXTRAS" | "REGISTER" | "SHOES AND SOCKS" | "WEB OFFERS" | "YOUR ORDER" | "RESERVATION ON HOLD" | "RESERVATION SUBMITTED" | "RESERVATION CONFIRMED" | "THANK YOU ON HOLD" | "THANK YOU SUBMITTED" | "THANK YOU CONFIRMED";
const ProviderConfigurationErrors = ["BowlerProviderSetupNotFound"] as const;
type ProviderConfigurationErrorCodes = typeof ProviderConfigurationErrors[number];

const useRest = useClientBuilder.createRestClient();
useRest().setOption("cacheInMemory", false);

export default function useSystemRestClient() {
	const { get } = useRest();

	function functionalities(systemId: number) {
		return get<IFunctionalities, IResponseError>(`/centers/${systemId}/functionalities`, {
			// FIXME: Update 404 Error Code when BE can provide its value (TS: #47457)
			throwExcluding: [{ statusCode: 404 }]
		});
	}

	function configurations(systemId: number, Configurations: SystemConfigurationName[]) {
		return get<ISystemConfigurations>(`/centers/${systemId}/configuration`, {
			query: { Configurations }
		});
	}

	function info(systemId: number) {
		return get<ISystemInfo, IResponseError>(`/centers/${systemId}`, {
			// FIXME: Update Typed Error Codes when BE can provide a list of them (TS: #47457)
			throwExcluding: [{ statusCode: 401 }, { statusCode: 404 }]
		});
	}

	function playerTypes(systemId: number) {
		return get<IPlayersTypes>(`/centers/${systemId}/player-types`);
	}

	function openingTimes(systemId: number, from: Date, to: Date) {
		return get<IOpeningTimes>(`/centers/${systemId}/opening-times/bookforlater/range`, {
			query: {
				fromDate: dateKey(from),
				toDate: dateKey(to)
			}
		});
	}

	function statusFoodAndBeverageAvailableAt(systemId: number, date: Date) {
		return get<IStatusFoodAndBeverageAvailableAt>(`/centers/${systemId}/opening-times/foodandbeverage/at`, {
			query: {
				dateTime: dateTimeKey(date)
			}
		});
	}

	function mainCurrency(systemId: number) {
		return get<ISystemCurrency, IResponseError>(`/centers/${systemId}/main-currency`, {
			// FIXME: Update 404 Error Code when BE can provide its value (TS: #47457)
			throwExcluding: [{ statusCode: 404 }]
		});
	}

	function templateGenericPage(systemId: number, pageType: PageType) {
		return get<IGenericPage>(`/centers/${systemId}/page/page/${pageType}`, { cacheInMemory: true });
	}

	function templateCommonParts(systemId: number) {
		return get<ICommonParts>(`/centers/${systemId}/commonparts`, { cacheInMemory: true });
	}

	function templateHomePage(systemId: number) {
		return get<IHomePage>(`/centers/${systemId}/page/homepage`, { cacheInMemory: true });
	}

	function templateReservationPage(systemId: number) {
		return get<IReservationPageResponse>(`/centers/${systemId}/page/reservation`, { cacheInMemory: true });
	}

	function templateThankYouPage(systemId: number) {
		return get<IThankYouPageResponse>(`/centers/${systemId}/page/thank-you`, { cacheInMemory: true });
	}

	function templateCookiePage(systemId: number) {
		return get<IGenericPage>(`/centers/${systemId}/bowlers-template/page/cookie-policy`, { cacheInMemory: true });
	}

	function templateNavigationBar(systemId: number) {
		return get<INavigationBar[]>(`/centers/${systemId}/NavigationBar`, { cacheInMemory: true });
	}

	function getProviderConfiguration(systemId: number) {
		return get<ProviderConfiguration, IResponseError<ProviderConfigurationErrorCodes>>(`/centers/${systemId}/ProviderConfiguration`, {
			throwExcluding: [
				error => {
					return ((error.statusCode === 404)
					&& typeof error.data?.Error?.Code === "string"
					&& ProviderConfigurationErrors.includes(error.data.Error.Code));
				}]
		});
	}

	function getTermsList(systemId: number) {
		return get<ITerms>(`/centers/${systemId}/Terms`);
	}

	function getTermsAndConditions(systemId: number) {
		return get<IPolicyTerm>(`/centers/${systemId}/Terms/BowlerBookForLaterTermsAndConditions`);
	}

	function getTermsOfUse(systemId: number) {
		return get<IPolicyTerm>(`/centers/${systemId}/Terms/TermsOfUse`);
	}

	function getMaintenanceModeStatus(systemId: number) {
		return get<boolean>(`/centers/${systemId}/MaintenanceMode`);
	}

	return {
		functionalities,
		configurations,
		info,
		playerTypes,
		openingTimes,
		statusFoodAndBeverageAvailableAt,
		mainCurrency,
		templateGenericPage,
		templateCommonParts,
		templateHomePage,
		templateReservationPage,
		templateThankYouPage,
		templateCookiePage,
		templateNavigationBar,
		getProviderConfiguration,
		getTermsList,
		getTermsAndConditions,
		getTermsOfUse,
		getMaintenanceModeStatus
	};
}
