import { trackView } from "@app-utilities/app-insights";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import guardInit from "./guard";
import BaseRoutes from "./routes/base";
import PolicyTermPagesRoutes from "./routes/policyTerms";
import ReservationRoutes from "./routes/reservations";
export const notFoundFallbackViewName = "not-found-fallback";

interface IScrollPosition {
	top: number,
	left: number
}
const topPosition: IScrollPosition = {
	top: 0, left: 0
};

const router = createRouter({
	linkActiveClass: "active",
	history: createWebHistory(),
	routes: [
		...BaseRoutes,
		...ReservationRoutes,
		...PolicyTermPagesRoutes,
		...[{
			path: "/not-found",
			name: "not-found",
			component: () => import("@app-views/service/not-found/not-found.vue")
		},
		{
			path: "/:pathMatch(.*)*",
			redirect: "/not-found"
		}
		]
	] as RouteRecordRaw[],
	scrollBehavior: (to, from, savedPosition) => {
		return new Promise(resolve => {
			if (savedPosition) resolve(savedPosition);
			else resolve({ ...topPosition, behavior: "smooth" });
		});
	}
});

router.beforeEach((to, from, next) => guardInit(to, from, next));
router.afterEach(to => {
	if (to.name)
		trackView(to.name.toString(), "end", to.fullPath);
});

export default router;
