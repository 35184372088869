import languages from "@app-locales/languages.json";
import { ITranslation } from "@qamf/xliff-loader";
import { ref } from "vue";

import BowlerApp from "@/main";

export type LanguageIso = keyof typeof languages;
const xlfObjectSource = ref(<ITranslation>{});
const xlfObject = ref(<ITranslation>{});

const usei18n = () => {
	let installed = false;

	function translateKey(key: string, obj?): string {
		if (!key || !xlfObject.value)
			return "";

		let str = ""; let translated = true;

		if (xlfObject.value.target && typeof xlfObject.value.target === "object" && key in xlfObject.value.target && xlfObject.value.target[key])
			str = xlfObject.value.target[key].trim();

		else if (xlfObject.value.source && typeof xlfObject.value.source === "object" && key in xlfObject.value.source && xlfObject.value.source[key])
			str = xlfObject.value.source[key].trim();

		else if (xlfObject.value.source && typeof xlfObject.value.source === "object" && key in xlfObjectSource.value.source && xlfObjectSource.value.source[key])
			str = xlfObjectSource.value.source[key].trim();

		else {
			translated = false;
			console.warn(`[i18n] The Translation-Key '${key}' was not translated`);
			str = `[${key.toUpperCase()}]`;
		}

		if (!str)
			console.warn(`[i18n] The Translation-Key '${key}' is empty.`);

		if (obj && translated) {
			const tokenRegex = /{{[a-zA-Z0-9]+}}/gm;
			const matches = str.matchAll(tokenRegex);
			let fixedString = String(str);
			for (const match of matches) {
				const token = match[0];
				fixedString = fixedString.replace(token, token.toLowerCase());
			}
			for (const prop in obj)
				fixedString = fixedString.replace(`{{${prop.toLowerCase()}}}`, obj[prop]);

			str = fixedString;
		}

		return str;
	}

	function install() {
		if (installed) return;
		installed = true;
		BowlerApp.config.globalProperties.$t = translateKey;

		if (import.meta.env.VUE_APP_LAUNCH_MODE === "DevelopSession") {
			import("@app-locales/index.xlf").then(xlf => {
				console.info(`[i18n] XLF Source file loaded, keys found: ${Object.keys(xlf.default.source).length}`);
			});
		}
	}

	return {
		xlfObjectSource,
		xlfObject,
		translateKey,
		install
	};
};

export default usei18n;
