import { useClientBuilder } from "@app-rest/index";
import { timeout } from "@app-utilities/func";
export interface ICompanyTheme {
	ThemeUrl: string;
}

export interface IApplyTheme {
	companyId: number;
	systemId: number;
	previewUrl?: string;
}

const useRest = useClientBuilder.createRestClient();

export default function useThemeRestClient() {
	const { get } = useRest();

	let _actual: ICompanyTheme | null = null;

	function getActualTheme(companyId: number, systemId: number) {
		return get<ICompanyTheme>(`/companies/${companyId}/themes/${systemId}/website/current`);
	}

	async function applyToPage(resource: string) {
		const request = await fetch(resource, {
			cache: "force-cache",
			credentials: "omit",
			mode: "cors",
			method: "GET",
			referrer: location.href,
			referrerPolicy: "no-referrer-when-downgrade",
			headers: new Headers({ "Access-Control-Allow-Origin": "*" })
		});
		const data = await request.text();
		const tag = document.createElement("style");
		tag.innerText = data;
		document.head.appendChild(tag);
		await timeout(1000);
	}

	async function applyActual(companyId: number, systemId: number) {
		const themeResource = await getActualTheme(companyId, systemId);
		if (_actual?.ThemeUrl === themeResource.data?.ThemeUrl) return themeResource.data?.ThemeUrl;
		_actual = themeResource.data;
		if (themeResource.data?.ThemeUrl) await applyToPage(themeResource.data?.ThemeUrl);
		return themeResource.data?.ThemeUrl;
	}

	async function applyThemeUrl(url: string) {
		await applyToPage(url);
		return url;
	}

	async function apply(options: IApplyTheme) {
		const port = parseInt(location.host.split(":")[1] ?? "0");
		const themesDisabled = port >= 3000 && port < 4000;
		let themeEndpoint;
		if (!themesDisabled) {
			if (options.previewUrl) themeEndpoint = await applyThemeUrl(options.previewUrl);
			else themeEndpoint = await applyActual(options.companyId, options.systemId);
		}
		const url = themeEndpoint ? new URL(themeEndpoint) : null;
		const parts = url?.pathname.substr(1).split("/") ?? [];
		return {
			url,
			companyId: parts.length && parts[0] !== "main" ? parts[0] : null,
			codename: parts.length ? parts[2] : "develop",
			revision: parts.length ? parts[3] : ""
		};
	}
	return {
		getActualTheme,
		applyActual,
		applyThemeUrl,
		apply
	};
}
