import { getReservationHomeHref, isB2CService } from "@app-router/helpers";
import useSystemStore from "@app-store/system";
import { QImg, QLink } from "@qamf/lighthouse";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "Logo",
	components: {
		QImg,
		QLink
	},
	props: {},
	setup() {
		const systemStore = useSystemStore();
		const route = useRoute();
		const imageLogo = ref<typeof QImg | null>();
		const logoUrl = computed(() => {
			return isB2CService(route)
				? systemStore.templateCommonParts?.LogoUrl
				: "/assets/images/qubica-logo.png";
		});
		const isMaintenanceModeActive = computed(() => {
			return systemStore.maintenanceMode;
		});
		const logoLink = computed(() => {
			if (isMaintenanceModeActive.value) return "";
			return isB2CService(route)
				? getReservationHomeHref(route)
				: "https://www.qubicaamf.com/";
		});
		return {
			logoUrl,
			logoLink,
			imageLogo
		};
	}
});
