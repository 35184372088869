export const home = "reservation-home";
export const offersPage = "reservation-offers";
export const footwearsPage = "reservation-footwears";
export const extrasPage = "reservation-extras";
export const foodBeveragePage = "reservation-food-beverage";
export const cartPage = "reservation-cart";
export const reservationBowlersPage = "reservation-bowlers";
export const thankyouPage = "reservation-thankyou";

const reservationRoutes = [
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/book",
		name: home,
		component: () => import("@app-views/book/home/home.vue")
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/book/offers",
		name: offersPage,
		component: () => import("@app-views/book/web-offers/web-offers.vue")
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/book/:reskey/food-beverage",
		name: foodBeveragePage,
		component: () => import("@app-views/book/food-beverage/food-beverage.vue")
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/book/:reskey/shoes-socks",
		name: footwearsPage,
		component: () => import("@app-views/book/shoes/shoes.vue")
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/book/:reskey/extras",
		name: extrasPage,
		component: () => import("@app-views/book/extras/extras.vue")
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/book/:reskey/cart",
		name: cartPage,
		component: () => import("@app-views/book/cart/cart.vue")
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/book/:reskey/reservation-bowlers",
		name: reservationBowlersPage,
		component: () => import("@app-views/book/reservation-bowlers/reservation-bowlers.vue")
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})/book/:reskey/thankyou",
		name: thankyouPage,
		component: () => import("@app-views/book/thankyou/thankyou.vue")
	}
];
export default reservationRoutes;
