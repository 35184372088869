import CartSummary from "@app-components/cart-summary/cart-summary.vue";
import Price from "@app-components/price/price.vue";
import { useResponsiveUtilities } from "@app-composables/responsive";
import usei18n from "@app-i18n/index";
import { cartPage, home, reservationBowlersPage, thankyouPage } from "@app-router/routes/reservations";
import useAppStore from "@app-store/app";
import useCartStore, { ICartItem } from "@app-store/cart";
import useReservationStore from "@app-store/reservation";
import useReservationFluxStore from "@app-store/reservation-flux";
import useSystemStore from "@app-store/system";
import useUserStore, { logout } from "@app-store/user";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import EventManager from "@app-utilities/events-manager";
import { ITimeoutStoppable } from "@app-utilities/func";
import { QBadge, QLink, QOffcanvas, QPopover, QToast, useToastController } from "@qamf/lighthouse";
import { BNavbarNav, BNavItem } from "bootstrap-vue-next";
import { computed, defineComponent, h, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "ServiceMenu",
	components: {
		QBadge,
		QLink,
		BNavbarNav,
		BNavItem,
		QOffcanvas,
		QToast,
		QPopover,
		CartSummary,
		Price
	},
	props: {},
	setup() {
		const appStore = useAppStore();
		const userStore = useUserStore();
		const reservationStore = useReservationStore();
		const cartStore = useCartStore();
		const { translateKey } = usei18n();
		const route = useRoute();
		const isPopoverVisible = ref(false);
		const { show: showToast } = useToastController();
		const showSidebar = ref(false);
		const serviceMenuElement = ref(null);
		const itemsAdded = ref<ICartItem[]>([]);
		const onCartUnsubscribe = ref(() => {});
		const onWindowResizeUnsubscribe = ref(() => {});
		const helpers = reactive({
			timeoutObject: null as ITimeoutStoppable | null
		});
		const isLoggedUser = computed(() => {
			return userStore.isLoggedIn();
		});
		const isReservationConfirmed = computed(() => {
			return reservationStore.isReservationConfirmed();
		});
		const firstName = computed(() => {
			return userStore.userData().FirstName;
		});
		const userName = computed(() => {
			return translateKey("common_hi_logged_user", {
				userName: userStore.userData().FirstName
			});
		});
		const isCartView = computed(() => {
			return route.name === cartPage;
		});
		const isReservationBowlersView = computed(() => {
			return route.name === reservationBowlersPage;
		});
		const isReservationThankyouView = computed(() => {
			return route.name === thankyouPage;
		});
		const cartIsEmpty = computed(() => {
			return cartStore.isEmpty();
		});
		const isCartVisible = computed(() => {
			return route.name !== home
					&& route.name !== thankyouPage
					&& route.name !== cartPage
					&& route.name !== reservationBowlersPage;
		});
		const itemsInCart = computed(() => {
			return cartStore.items.reduce((acc, item) => acc + item.Quantity, 0);
		});
		const systemId = computed(() => useSystemStore().info?.Id ?? 0);
		const responsiveUtils = useResponsiveUtilities();
		const toastClasses = computed(() => {
			return {
				...responsiveUtils.responsiveClasses.value,
				"cart-item-added": true
			};
		});
		const onLoginClick = () => {
			userStore.login();
		};
		const itemAddedLabel = computed(() => {
			const itemsAddedQty = itemsAdded.value.reduce(
				(acc, el) => acc + el.Quantity,
				0
			);
			if (itemsAddedQty === 0) return;
			if (itemsAddedQty === 1)
				return translateKey("cart_item_added");
			return translateKey("cart_items_added", {
				qty: itemsAddedQty
			});
		});
		const totalWithoutTaxes = computed(() => {
			return cartStore.totalWithoutTaxes();
		});
		const allMandatoryPagesVisited = computed(() => {
			if (!reservationStore.mandatoryPages) return false;

			const notVisitedViews = reservationStore.mandatoryPages.filter(el => !el.visited);

			if (notVisitedViews.length > 0) return false;

			return true;
		});
		const checkoutButtonLabel = computed(() => {
			if (!allMandatoryPagesVisited.value) return translateKey("label_continue");

			return translateKey("label_checkout");
		});
		const totalItemPrice = (item: ICartItem) => {
			return item.UnitPrice * item.Quantity;
		};
		const destroy = () => {
			onCartUnsubscribe.value();
			onWindowResizeUnsubscribe.value();
		};
		const cartItemAddedOpen = () => {
			if (isCartView.value) return;

			const toasterContent = itemsAdded.value.map((el) => {
				return h("div", { class: "cart-added-list-item" }, [
					h("div", { class: "col-title" }, `${el.Quantity}x ${el.Title}`),
					h("div", { class: "col-price" }, h(Price, { value: totalItemPrice(el) }))
				]);
			});
			showToast?.({
				props: {
					variant: "light",
					pos: "top-end",
					interval: 100,
					value: 4000,
					toastClass: toastClasses.value,
					noCloseButton: true,
					noHoverPause: true,
					title: itemAddedLabel.value,
					isStatus: true
				},
				component: h(QToast, null, () => h("div", { class: "cart-added-list" }, toasterContent))
			});
		};
		const toggleBodyScrollbar = (visible: boolean) => {
			const body = document.getElementsByTagName("body")[0];

			if (visible)
				body.classList.add("overflow-hidden");
			else
				body.classList.remove("overflow-hidden");
		};
		const checkoutButtonClicked = () => {
			appStore.setLoadingView(true);
			showSidebar.value = false;
			if (!allMandatoryPagesVisited.value) {
				trackUserEvent(EventAI.ContinueFromCartSidebarClicked);
				return useReservationFluxStore().goNextView(route);
			}

			trackUserEvent(EventAI.CheckoutFromCartSidebarClicked);
			return useReservationFluxStore().jumpToView({
				target: {
					...route,
					name: cartPage
				}
			});
		};
		const showCart = () => {
			if (cartIsEmpty.value) return;
			showSidebar.value = true;
			trackUserEvent(EventAI.CartOpened);
		};
		const hideCart = () => {
			showSidebar.value = false;
			trackUserEvent(EventAI.CartClosed);
		};
		const openPopOver = () => {
			isPopoverVisible.value = true;
		};
		const onLogoutClick = () => {
			// FIXME: review hack to handle logout with usePopOverController #73163
			isPopoverVisible.value = false;
		};
		onMounted(() => {
			responsiveUtils.init();
			onCartUnsubscribe.value = EventManager.onCartItemsAdded((items) => {
				itemsAdded.value = items;
				cartItemAddedOpen();
			});
		});
		onUnmounted(() => {
			responsiveUtils.destroy();
		});
		watch(() => route.name, () => {
			if (showSidebar.value)
				showSidebar.value = false;
		});
		return {
			showSidebar,
			serviceMenuElement,
			isLoggedUser,
			isReservationConfirmed,
			firstName,
			userName,
			isCartView,
			isPopoverVisible,
			isReservationBowlersView,
			isReservationThankyouView,
			cartIsEmpty,
			isCartVisible,
			itemsInCart,
			systemId,
			toastClasses,
			itemsAdded,
			onCartUnsubscribe,
			onWindowResizeUnsubscribe,
			helpers,
			itemAddedLabel,
			totalWithoutTaxes,
			responsiveUtils,
			checkoutButtonLabel,
			onLoginClick,
			logout,
			toggleBodyScrollbar,
			destroy,
			showCart,
			hideCart,
			openPopOver,
			onLogoutClick,
			checkoutButtonClicked
		};
	}
});
