import { defineComponent } from "vue";

export default defineComponent({
	name: "NavButton",
	components: {},
	props: {
		title: { type: String, required: false, default: "" }
	},
	emits: ["click"],
	setup(props, { emit }) {
		const onBtnClick = (e) => {
			emit("click", e);
		};
		return {
			onBtnClick
		};
	}
});
