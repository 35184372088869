import BowlerApp from "@/main";

import usei18n from "./index";

const i18nPlugin = {
	install() {
		const { translateKey } = usei18n();
		BowlerApp.config.globalProperties.$t = translateKey;

		if (import.meta.env.VUE_APP_LAUNCH_MODE === "DevelopSession") {
			import("@app-locales/index.xlf").then(xlf => {
				console.info(`[i18n] XLF Source file loaded, keys found: ${Object.keys(xlf.default.source).length}`);
			});
		}
	}
};

export default i18nPlugin;
