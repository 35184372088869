import usei18n from "@app-i18n/index";
import useCompanyRestClient from "@app-rest/company";
import { PolicyType, ReceiveOffersFlags } from "@app-rest/company.interfaces";
import useCompanyStore from "@app-store/company";
import useSystemStore from "@app-store/system";
import { IPrivacyChoice } from "@app-store/user";
import { QFormFieldCheckbox, QFormFieldRadio, useForm } from "@qamf/lighthouse";
import { computed, defineComponent, reactive, ref, watch } from "vue";

export default defineComponent({
	name: "PrivacyModal",
	components: {
		QFormFieldCheckbox,
		QFormFieldRadio
	},
	props: {
		privacyLink: { type: String, required: true }
	},
	emits: ["proceed", "decline"],
	setup(props, { emit }) {
		const { translateKey } = usei18n();
		const centerName = computed(() => useSystemStore().info?.Name ?? "");
		const { validate } = useForm();
		const offers = ref<ReceiveOffersFlags>("KO");
		const innerLoading = ref(false);
		const offersOptions = ref<{text: string, value: ReceiveOffersFlags }[]>([
			{ text: translateKey("label_accept"), value: "OK" },
			{ text: translateKey("label_do_not_accept"), value: "KO" }
		]);
		const helpers = reactive({
			privacy: null as boolean | null
		});
		const declineButtonClicked = () => {
			emit("decline");
		};
		const proceedButtonClicked = async() => {
			const isValid = await validate();
			if (!isValid) return;
			const restClient = useCompanyRestClient();
			const response = await restClient.privacy(useCompanyStore().Id);
			const privacyData = response.data;
			if (!privacyData) return;
			const data: IPrivacyChoice[] = privacyData.map((e) => {
				let value: boolean | null = null;
				switch (e.Name) {
					case PolicyType.TermsAndConditions:
						value = helpers.privacy;
						break;
					case PolicyType.ReceiveOffers:
						value = offers.value === "OK";
						break;
				}
				return {
					Accepted: Boolean(value),
					PrivacyName: e.Name,
					PrivacyVersion: e.Version
				};
			});
			emit("proceed", data);
		};
		watch(
			() => innerLoading.value,
			(state: boolean) => {
				innerLoading.value = state;
			}
		);
		return {
			centerName,
			offers,
			innerLoading,
			offersOptions,
			helpers,
			declineButtonClicked,
			proceedButtonClicked
		};
	}
});
