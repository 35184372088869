import { RouteRecordRaw } from "vue-router";

import { home } from "./reservations";

export const maintenanceMode = "maintenance-mode";

const baseRoutes: RouteRecordRaw[] = [
	{
		path: "/",
		name: "root",
		redirect: null as any
	},
	{
		path: "/:companyId(\\d{1,})",
		name: "root-company",
		redirect: null as any
	},
	{
		path: "/:companyId(\\d{1,})/:systemId(\\d{4,})",
		name: "root-company-system",
		redirect: { name: home }
	},
	{
		path: "/:companyId(\\d{1,})/:systemId(\\d{4,})/maintenance-mode",
		name: maintenanceMode,
		component: () => import("@app-views/maintenance-mode/maintenance-mode.vue")
	},
	{
		path: "/browser-not-supported",
		name: "browser-not-supported",
		component: () => import("@app-views/service/browser-not-supported.vue")
	},
	{
		path: "/disabled-features",
		name: "disabled-features",
		component: () => import("@app-views/service/disabled-features.vue")
	},
	{
		path: "/error",
		name: "error-page",
		component: () => import("@app-views/service/error/error.vue")
	}
];

export default baseRoutes;
