<template>
	<div class="logo-wrapper">
		<QLink
			exact
			:to="logoLink"
			class="d-inline-block"
		>
			<QImg
				v-if="logoUrl"
				ref="imageLogo"
				:src="logoUrl"
				alt="logo image"
			/>
		</QLink>
	</div>
</template>

<script lang="ts" src="./logo.ts" />
