import { isProxy, isReactive, isRef, toRaw } from "vue";

export function timeout(ms?: number | null) {
	const type = typeof ms;
	if (type === "undefined" || ms === null)
		ms = 0;
	else if (type !== "number")
		throw new Error(`Bad argument type, provided "${type}", expected "number".`);
	return new Promise(resolve => setTimeout(resolve, ms as number));
}

export interface ITimeoutStoppable {
	id: number;
	running: boolean;
	end: Promise<never>;
	stoppedBeforeEnd: boolean;
	stop: { (): void }
}
export function timeoutStoppable(ms?: number | null) {
	const type = typeof ms;
	if (type === "undefined" || ms === null)
		ms = 0;
	else if (type !== "number")
		throw new Error(`Bad argument type, provided "${type}", expected "number".`);

	const tobj: ITimeoutStoppable = {
		id: 0,
		running: true,
		end: new Promise(() => {}),
		stop() {
			// do nothing.
		},
		stoppedBeforeEnd: false
	};
	tobj.end = new Promise(resolve => {
		tobj.id = setTimeout(resolve, ms as number) as any;

		tobj.stop = () => {
			if (tobj.id && tobj.running)
				clearTimeout(tobj.id);

			if (tobj.running)
				tobj.stoppedBeforeEnd = true;

			tobj.running = false;
		};
	});
	return tobj;
}

export function deepToRaw<T extends Record<string, any>>(sourceObj: T): T {
	const objectIterator = (input: any): any => {
		if (Array.isArray(input))
			return input.map((item) => objectIterator(item));
		if (isRef(input) || isReactive(input) || isProxy(input))
			return objectIterator(toRaw(input));
		if (input && input instanceof Date)
			return input;
		if (input && typeof input === "object") {
			return Object.keys(input).reduce((acc, key) => {
				acc[key as keyof typeof acc] = objectIterator(input[key]);
				return acc;
			}, {} as T);
		}
		return input;
	};

	return objectIterator(sourceObj);
}
