<template>
	<div class="privacy-modal form">
		<Spinner
			v-if="innerLoading"
			size="md"
		/>
		<div class="center-privacy">
			<p>{{ $t('signup_modal_text', { centerName: centerName }) }} {{ $t('signup_privacy_text', { centerName: centerName }) }}</p>
			<QFormFieldCheckbox
				v-model="helpers.privacy"
				name="privacy-check"
				:label="$t('common_accept_privacy_policy')"
				rules="required"
				:error-message="$t('common_required_field_error')"
			>
				<a
					:href="privacyLink"
					target="_blank"
				>{{ $t("common_accept_privacy_policy") }}</a>
			</QFormFieldCheckbox>
		</div>
		<hr>
		<div class="extra-offers">
			<p>{{ $t("common_would_receive_offers_email_sms") }}</p>
			<QFormFieldRadio
				v-model="offers"
				name="offers-check"
				:options="offersOptions"
				:error-message="$t('common_required_field_error')"
			/>
		</div>
		<div class="wrap-buttons">
			<q-button
				variant="primary"
				@click="proceedButtonClicked"
			>
				{{ $t("label_proceed") }}
			</q-button>
			<span class="sep">{{ $t("label_or") }}</span>
			<q-button
				variant="outline-primary"
				@click="declineButtonClicked"
			>
				{{ $t("label_decline_and_logout") }}
			</q-button>
		</div>
	</div>
</template>

<script lang="ts" src="./privacy-modal.ts" />
<style lang="scss" src="./privacy-modal.scss"></style>
