import Copyright from "@app-components/copyright/copyright.vue";
import FooterMenu from "@app-components/footer-menu/footer-menu.vue";
import Layer from "@app-components/layer.vue";
import Logo from "@app-components/logo/logo.vue";
import Menubar from "@app-components/menubar/menubar.vue";
import MissingFunctionalities from "@app-components/missing-functionalities/missing-functionalities.vue";
import ServiceMenu from "@app-components/service-menu/service-menu.vue";
import { isB2CService, isFullscreenPage, isSkippable } from "@app-router/helpers";
import { home } from "@app-router/routes/reservations";
import useAppStore from "@app-store/app";
import store from "@app-store/index";
import { setupPersistentModules } from "@app-store/persistent.plugin";
import useSystemStore from "@app-store/system";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import { isErrorPage } from "@app-utilities/const";
import EventsManager from "@app-utilities/events-manager";
import { initModalUtil } from "@app-utilities/modals";
import { azurePopupWindowFocus } from "@app-utilities/popup";
import { initToastUtil } from "@app-utilities/toasts";
import { QModalOrchestrator, QOverlay, QToastOrchestrator, useModalController, useToastController } from "@qamf/lighthouse";
import { AppDriver } from "cypress/support/commands";
import { debounce } from "lodash-es";
import {
	computed,
	defineComponent,
	getCurrentInstance,
	nextTick,
	onMounted,
	ref,
	watch
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
	name: "Bowler",
	components: {
		QOverlay,
		QToastOrchestrator,
		QModalOrchestrator,
		FooterMenu,
		Copyright,
		Layer,
		Logo,
		Menubar,
		ServiceMenu,
		MissingFunctionalities
	},
	props: {},
	setup() {
		const appStore = useAppStore();
		const logo = ref<typeof Logo>();
		const serviceMenu = ref<typeof ServiceMenu>();
		const systemStore = useSystemStore();
		const router = useRouter();
		const route = useRoute();
		const modalController = useModalController();
		const toastController = useToastController();
		const themeLoaded = computed(() => {
			return appStore.themeLoaded;
		});
		const isB2CServiceView = computed(() => {
			return isB2CService(route);
		});
		const isFullscreenView = computed(() => {
			return isFullscreenPage(route);
		});
		const isSkippableView = computed(() => {
			return isSkippable(route);
		});
		const isErrorView = computed(() => {
			return isErrorPage(route.name);
		});
		const isPopupOpen = computed(() => {
			return appStore.azurePopupWindowIsOpen;
		});
		const isMaintenanceModeActive = computed(() => {
			return systemStore.maintenanceMode;
		});
		const theme = computed(() => {
			return isB2CService(route) ? appStore.themeName : "qamf";
		});
		const loadingView = computed(() => {
			return appStore.loadingView;
		});
		const isMissingRequiredFunctionalities = computed(() => {
			return systemStore.functionalities && !systemStore.functionalities?.WebReservations;
		});
		const currentViewName = computed(() => {
			return route.name;
		});
		const isBookingHome = computed(() => {
			return route.name === home;
		});
		const footerContents = computed(() => {
			return systemStore.templateCommonParts?.Footers ?? [];
		});
		const isDisabled = computed(() => {
			return route.name === "qamf-maintenance-mode";
		});
		const headerLayer = ref<any>();
		const currentView = ref<any>();
		const currentViewComponent = ref<any>();
		const isSticky = ref(false);
		const onResizeApp = () => {
			EventsManager.emitWindowResize();
			return nextTick();
		};
		const mainOverlayClicked = () => {
			azurePopupWindowFocus();
		};
		const formatClass = (index: number) => {
			const position = ["left", "right"];
			return position[index];
		};
		const onScrollApp = () => {
			if (!headerLayer.value) return;
			const stickyHeader = headerLayer.value.$el.scrollTop;
			isSticky.value = window.scrollY > stickyHeader;
		};
		const updateThemeColor = () => {
			const color = window.getComputedStyle(headerLayer.value?.$el ?? document.createElement("div"), null).backgroundColor;
			if (color !== "") localStorage.setItem("themeColorPwa", color);
		};
		watch(
			() => loadingView.value,
			async(old: boolean, loading: boolean) => {
				await nextTick();
				if (!loading) return;
				const layer = headerLayer.value?.$el;
				if (!layer) return;
				layer.scrollIntoView({
					behavior: "smooth",
					block: "start"
				});
			}
		);
		const getCurrentViewProxyInfo = async() => {
			await nextTick();
			if (currentView.value) {
				const currentComponent = currentView.value.$.subTree.component;
				currentViewComponent.value = currentComponent.proxy;
			}
		};
		watch(route, async() => {
			await getCurrentViewProxyInfo();
		});
		onMounted(async() => {
			window.onresize = debounce(onResizeApp, 200);
			onResizeApp();
			window.onscroll = debounce(() => EventsManager.emitScrollWindow());
			EventsManager.onScrollWindow(onScrollApp);
			onScrollApp();
			await appStore.ensureRequiredBrowserFeatures();
			if (!appStore.hasAllRequiredBrowserFeatures()) {
				trackUserEvent(EventAI.RedirectToDisabledFeatures, {
					browser: appStore.browser,
					isValidBrowser: appStore.isValidBrowser(),
					browserFeatures: appStore.requiredBrowserFeatures
				});
				return router
					.replace({
						name: "disabled-features"
					})
					.catch(() => { });
			}
			if (!appStore.isValidBrowser()) {
				trackUserEvent(EventAI.RedirectToUnsupportedBrowser, {
					browser: appStore.browser,
					isValidBrowser: appStore.isValidBrowser(),
					browserFeatures: appStore.requiredBrowserFeatures
				});
				return router
					.replace({
						name: "browser-not-supported"
					})
					.catch(() => { });
			}
			const usePersistence = setupPersistentModules({
				name: "BowlerVuexModules",
				stores: [
					{ name: "user", params: { autoIncrement: false } },
					{ name: "cart", params: { autoIncrement: false } },
					{ name: "reservation", params: { autoIncrement: false } }
				]
			});
			usePersistence(store);
			if (appStore.isTestingSession() || appStore.isDevelopSession()) {
				const appInstance = getCurrentInstance();
				const appDriver = {
					getServiceMenu: () => serviceMenu.value,
					getLogo: () => logo.value,
					getStore: () => useStore(),
					getInstance: () => appInstance?.root,
					getView: () => currentViewComponent.value,
					getRef: (refName) => appInstance?.refs[refName] || null,
					getRouter: () => router
				} as AppDriver;
				(window as any).app = appDriver;
			}
			initModalUtil(modalController);
			initToastUtil(toastController);
		});
		return {
			themeLoaded,
			theme,
			isFullscreenView,
			isB2CServiceView,
			isSkippableView,
			isErrorView,
			isMaintenanceModeActive,
			isMissingRequiredFunctionalities,
			isPopupOpen,
			loadingView,
			currentViewName,
			isBookingHome,
			footerContents,
			isDisabled,
			headerLayer,
			currentView,
			isSticky,
			serviceMenu,
			logo,
			mainOverlayClicked,
			formatClass,
			updateThemeColor
		};
	}
});
