<template>
	<div class="menubar">
		<b-navbar
			toggleable="lg"
			class="navbar-menu"
		>
			<b-navbar-toggle target="nav-collapse">
				<template #default="{ expanded }">
					<i
						v-if="!expanded"
						class="bi bi-list"
					/>
					<i
						v-else
						class="bi bi-x"
					/>
				</template>
			</b-navbar-toggle>
			<q-collapse
				id="nav-collapse"
				is-nav
				class="menu-items-collapser"
			>
				<b-navbar-toggle target="nav-collapse">
					<template #default="{ expanded }">
						<i
							v-if="!expanded"
							class="bi bi-list"
						/>
						<i
							v-else
							class="bi bi-x"
						/>
					</template>
				</b-navbar-toggle>
				<b-navbar-nav
					v-if="hasMinimumData"
					class="menu-items"
				>
					<b-nav-item
						v-for="menuVoice in menuVoices"
						:key="menuVoice.label"
						:href="ensureVoiceMenuUrl(menuVoice.url)"
						:to="!menuVoice.isExternal ? menuVoice.url : undefined"
						target="_blank"
						class="menu-item"
					>
						{{ menuVoice.label }}
					</b-nav-item>
				</b-navbar-nav>
			</q-collapse>
		</b-navbar>
	</div>
</template>

<script lang="ts" src="./menubar.ts"></script>
