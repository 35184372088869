import { IResponseError, useClientBuilder } from "@app-rest/index";
import { ICustomerInfo } from "@app-store/user";

import type { IPolicyTerm, IPolicyTermMenu } from "./common.interfaces";
import type { ICompany, ICompanyLocationGroup, IPrivacyData, SignupBody } from "./company.interfaces";

const CustomerErrors = ["CustomerNotFound"] as const;
type CustomerErrorCodes = typeof CustomerErrors[number];
const CustomerPrivacyErrors = ["PrivacyNotFound", "CustomerExists"] as const;
type CustomerPrivacyErrorCodes = typeof CustomerPrivacyErrors[number];

const useRest = useClientBuilder.createRestClient();
useRest().setOption("basePath", "/api/companies");

export default function useCompanyRestClient() {
	const { get, post, put } = useRest();

	function info(companyId: number) {
		return get<ICompany>(`/${companyId}`, {
			// FIXME: Update 404 Error Code when BE can provide its value (TS: #47457)
			throwExcluding: [{ statusCode: 404 }]
		});
	}

	function locations(companyId: number) {
		return get<ICompanyLocationGroup[]>(`/${companyId}/locations`);
	}

	function privacy(companyId: number) {
		return get<IPrivacyData[]>(`/${companyId}/privacy`);
	}

	function getCustomer(companyId: number) {
		return get<ICustomerInfo, IResponseError<CustomerErrorCodes>>(`/${companyId}/customers/me`, {
			throwExcluding: [error => {
			// FIXME: Remove when the BE can provide a list of all 401 Error Codes to be handled below (TS: #47457)
				if (error.statusCode === 401)
					return true;

				return ((error.statusCode === 404)
					&& typeof error.data?.Error?.Code === "string"
					&& CustomerErrors.includes(error.data.Error.Code));
			}]
		});
	}

	function linkCustomerToCompany(companyId: number, body: SignupBody) {
		return post<any, IResponseError<CustomerPrivacyErrorCodes>>(`/${companyId}/customers`, {
			body,
			throwExcluding: [
				error => {
					return ((error.statusCode === 404 || error.statusCode === 409)
					&& typeof error.data?.Error?.Code === "string"
					&& CustomerPrivacyErrors.includes(error.data.Error.Code));
				}]
		});
	}

	function linkCustomerToSystem(companyId: number, systemId: number) {
		return put<any, IResponseError<CustomerErrorCodes>>(`/${companyId}/customers/me/centers/${systemId}`, {
			cacheInMemory: false,
			throwExcluding: [error => {
				return ((error.statusCode === 404)
					&& typeof error.data?.Error?.Code === "string"
					&& CustomerErrors.includes(error.data.Error.Code));
			}]
		});
	}

	function getPolicyList(companyId: number) {
		return get<IPolicyTermMenu[]>(`/${companyId}/Agreements`);
	}

	function getPrivacyPolicy(companyId: number) {
		return get<IPolicyTerm>(`/${companyId}/Agreements/PrivacyPolicy`, { cacheInMemory: true });
	}

	return {
		info,
		locations,
		privacy,
		getCustomer,
		linkCustomerToCompany,
		linkCustomerToSystem,
		getPolicyList,
		getPrivacyPolicy
	};
}
