<template>
	<div class="error missing-functionalities">
		<img
			src="/assets/images/ico-alert.svg"
			:alt="$t('common_error_title_oops_not_found')"
			:title="$t('common_error_title_oops_not_found')"
		>
		<h1 class="title">
			{{ $t("common_error_title_oops_not_found") }}
		</h1>
		<p>{{ $t("common_error_message_oops_not_found") }} <br> {{ $t("common_error_message_check_url_try_again") }}</p>
	</div>
</template>
<script lang="ts" src="./missing-functionalities.ts"></script>
<style lang="scss" src="./missing-functionalities.scss"></style>
