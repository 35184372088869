import useCompanyStore from "@app-store/company";
import useLangStore from "@app-store/lang";
import useSystemStore from "@app-store/system";
import { QCollapse } from "@qamf/lighthouse";
import { BNav, BNavbar, BNavbarNav, BNavbarToggle, BNavItem } from "bootstrap-vue-next";
import { computed, defineComponent } from "vue";

export default defineComponent({
	name: "Menubar",
	components: {
		QCollapse,
		BNavbar,
		BNavbarNav,
		BNavbarToggle,
		BNav,
		BNavItem
	},
	props: {},
	setup() {
		const navigationBar = computed(() => {
			return useSystemStore().navigationBar ?? [];
		});
		const hasMinimumData = computed(() => {
			return useLangStore().isoCode && useCompanyStore().Id && useSystemStore().info?.Id;
		});
		const ensureVoiceMenuUrl = (url: string) => {
			const regex = /^http[s]?:\/\//;
			if (!regex.test(url)) return `https://${url}`;
			return url;
		};
		const menuVoices = computed(() => {
			return navigationBar.value.map((menu) => ({
				label: menu.Label,
				url: menu.Url,
				isExternal: true
			}));
		});
		return {
			navigationBar,
			hasMinimumData,
			ensureVoiceMenuUrl,
			menuVoices
		};
	}
});
