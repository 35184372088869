<template>
	<div>
		<q-button
			variant="nav"
			data-test="nav-button"
			@click="onBtnClick"
		>
			<span class="sr-only">{{ title }}</span>
		</q-button>
	</div>
</template>
<script lang="ts" src="./nav-button.ts"></script>
