/* eslint-disable @typescript-eslint/ban-types */
import languages from "@app-locales/languages.json";
import { ITranslation } from "@qamf/xliff-loader";
export type LanguageIso = keyof typeof languages;

export function importXlf(isoCode: LanguageIso): Promise<{ default: ITranslation }> {
	let iso = Object.keys(languages ?? {}).find(k => k === isoCode) as LanguageIso | undefined;

	if (!iso) {
		const langs = Object.keys(languages ?? {}).map(k => { return { Code: k } });
		const langParts = isoCode.split("-");
		const firstMatch = langs.find(l => l.Code.indexOf(langParts[0] + "-") > -1);
		if (firstMatch && firstMatch.Code !== "en-US")
			iso = firstMatch.Code as LanguageIso;
	}

	switch (iso) {
		case "en-GB":
			return import("@qamf/qst-bowler/en-US/index.xlf");
		case "de-DE":
			return import("@qamf/qst-bowler/de-DE/index.xlf");
		case "fr-FR":
			return import("@qamf/qst-bowler/fr-FR/index.xlf");
		case "fr-CA":
			return import("@qamf/qst-bowler/fr-CA/index.xlf");
		case "it-IT":
			return import("@qamf/qst-bowler/it-IT/index.xlf");
		case "es-ES":
		case "es-MX":
		case "es-NI":
			return import("@qamf/qst-bowler/es-ES/index.xlf");
		case "nb-NO":
			return import("@qamf/qst-bowler/nb-NO/index.xlf");
		case "sv-SE":
			return import("@qamf/qst-bowler/sv-SE/index.xlf");
		case "da-DK":
			return import("@qamf/qst-bowler/da-DK/index.xlf");
		case "id-ID":
			return import("@qamf/qst-bowler/id-ID/index.xlf");
		case "nl-NL":
			return import("@qamf/qst-bowler/nl-NL/index.xlf");
		case "ja-JP":
			return import("@qamf/qst-bowler/ja-JP/index.xlf");
		default:
			return import("@qamf/qst-bowler/en-US/index.xlf");
	}
}
export const datesDictionary: Record<LanguageIso, () => Promise<Locale>> = {
	"en-US": () => import("date-fns/locale/en-US") as Promise<Locale>,
	"en-GB": () => import("date-fns/locale/en-GB") as Promise<Locale>,
	"de-DE": () => import("date-fns/locale/de") as Promise<Locale>,
	"fr-FR": () => import("date-fns/locale/fr") as Promise<Locale>,
	"fr-CA": () => import("date-fns/locale/fr-CA") as Promise<Locale>,
	"it-IT": () => import("date-fns/locale/it") as Promise<Locale>,
	"es-ES": () => import("date-fns/locale/es") as Promise<Locale>,
	"es-MX": () => import("@app-i18n/locale/es-MX") as Promise<Locale>,
	"es-NI": () => import("@app-i18n/locale/es-NI") as Promise<Locale>,
	"nb-NO": () => import("date-fns/locale/nb") as Promise<Locale>,
	"sv-SE": () => import("date-fns/locale/sv") as Promise<Locale>,
	"da-DK": () => import("date-fns/locale/da") as Promise<Locale>,
	"id-ID": () => import("date-fns/locale/id") as Promise<Locale>,
	"nl-NL": () => import("date-fns/locale/nl") as Promise<Locale>,
	"ja-JP": () => import("date-fns/locale/ja") as Promise<Locale>
};
export const validationDictionary: Record<LanguageIso, () => Promise<Object>> = {
	"en-US": () => import("@vee-validate/i18n/dist/locale/en.json") as Promise<Object>,
	"de-DE": () => import("@vee-validate/i18n/dist/locale/de.json") as Promise<Object>,
	"en-GB": () => import("@vee-validate/i18n/dist/locale/en.json") as Promise<Object>,
	"fr-FR": () => import("@vee-validate/i18n/dist/locale/fr.json") as Promise<Object>,
	"fr-CA": () => import("@vee-validate/i18n/dist/locale/fr.json") as Promise<Object>,
	"it-IT": () => import("@vee-validate/i18n/dist/locale/it.json") as Promise<Object>,
	"es-ES": () => import("@vee-validate/i18n/dist/locale/es.json") as Promise<Object>,
	"es-MX": () => import("@vee-validate/i18n/dist/locale/es.json") as Promise<Object>,
	"es-NI": () => import("@vee-validate/i18n/dist/locale/es.json") as Promise<Object>,
	"nb-NO": () => import("@vee-validate/i18n/dist/locale/nb_NO.json") as Promise<Object>,
	"sv-SE": () => import("@vee-validate/i18n/dist/locale/sv.json") as Promise<Object>,
	"da-DK": () => import("@vee-validate/i18n/dist/locale/da.json") as Promise<Object>,
	"id-ID": () => import("@vee-validate/i18n/dist/locale/id.json") as Promise<Object>,
	"nl-NL": () => import("@vee-validate/i18n/dist/locale/nl.json") as Promise<Object>,
	"ja-JP": () => import("@vee-validate/i18n/dist/locale/ja.json") as Promise<Object>
};
