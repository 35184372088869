import { ICompany } from "@app-rest/company.interfaces";
import useLangStore from "@app-store/lang";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

@Module class CompanyStoreFactory extends VuexModule implements ICompany {
	Id = 0;
	Name = "";
	Language = "";

	@Mutation setCompany(data: ICompany | null) {
		this.Id = data?.Id ?? 0;
		this.Name = data?.Name ?? "";
		this.Language = data?.Language ?? useLangStore().defaultIsoCode;
	}

	@Action setCompanyData(data: ICompany | null) {
		this.setCompany(data);
	}
}

const moduleName = "company";

let CompanyStore: CompanyStoreFactory | null;

function useCompanyStore() {
	if (CompanyStore) return CompanyStore;

	const mod = CompanyStore = new CompanyStoreFactory({ store, name: moduleName });

	return mod;
}

export default useCompanyStore;
