import { IResponseError, useClientBuilder } from "@app-rest/index";
import type { IPayAndConfirmResultPayload, IPaypalCompletionPayload } from "@app-store/reservation";
import useReservationStore from "@app-store/reservation";
import { dateTimeKey } from "@app-utilities/dates";

import {
	type IFoodBeverage, type IFoodBeverageCategory, type IFootwearResponse, type IItemModifierGroupBE, type IOffer,
	type IOfferQueryCategoryId, type IOfferQueryDate, type IOfferQueryPagination,
	type IOfferQueryPlayers, type IOffersQuery, type IOffersQuerySystem, type IPackageModifierGroupBE, type IReservationBowlerOptions, type IReservationConfirmData, type IReservationConfirmResponse, type IReservationExtra, type IReservationGuestConfirmData, type IReservationLifetime, 	type IReservationOptions,
	type IReservationRequest, type IReservationStatusResponse, type IShoesCategoryData, type IWebOfferOptions, type ReservationConfirmError, type ReservationDeleteError, type ReservationError, type ReservationLifetimeError, type ReservationOffersError, type ReservationStatus, type ReservationStatusErrorCodes,
	ReservationStatusErrors,
	type ReservationTimeoutError
} from "./reservations.interfaces";

function buildQueryParams(query: Partial<IOffersQuery>
						& Partial<IOfferQueryPagination>
						& Partial<IOfferQueryDate>
						& Partial<IOfferQueryPlayers>
						& Partial<IOfferQueryCategoryId>) {
	if (!query.page) query.page = 1;
	if (!query.itemsPerPage) query.itemsPerPage = 50;
	if (query.datetime instanceof Date) {
		if (isNaN(+query.datetime))
			query.datetime = "";
		else query.datetime = dateTimeKey(query.datetime);
	}
	if (typeof query.players !== "string" && typeof query.players !== "undefined") {
		query.players = query.players
			.map(p => `${p.Id}-${p.Quantity}`)
			.join(",");
	}

	return query;
}

const useRest = useClientBuilder.createRestClient();

export default function useReservationRestClient() {
	const { get, post, patch, del, put } = useRest();
	const token = useReservationStore().reservationToken;

	if (token) {
		const headers = useRest().cloneOptions().headers ?? new Headers();
		headers.set("X-SessionToken", token);
		useRest().setOption("headers", headers);
	}

	function offers(query: IOffersQuerySystem & IOfferQueryDate & IOfferQueryPlayers) {
		return get<IOffer[], IResponseError<ReservationOffersError>>(
			`/centers/${query.systemId}/offers-availability`, {
				query: buildQueryParams(query),
				throwExcluding: [
					error => {
						const errorCode = error?.data?.Error?.Code;

						return error.statusCode === 409 && errorCode === "QDateTimeNotValid";
					}
				]
			});
	}

	function foodBeverageCategories(query: IOffersQuery & IOfferQueryDate) {
		return get<IFoodBeverageCategory[]>(
			`/centers/${query.systemId}/offers/${query.offerId}/food-beverage-categories`, {
				query: buildQueryParams(query),
				cacheInMemory: true
			});
	}

	function foodBeverage(query: IOffersQuerySystem & IOfferQueryDate & IOfferQueryCategoryId & Partial<IOfferQueryPagination>) {
		return get<IFoodBeverage[]>(`/centers/${query.systemId}/offers/food-beverage`, {
			query: buildQueryParams(query)
		});
	}

	function getItemFoodBeverageModifiers(systemId: number, pricekeyId: number) {
		return get<IItemModifierGroupBE>(`/centers/${systemId}/Items/${pricekeyId}/Modifiers`);
	}

	function getPackageFoodBeverageModifiers(systemId: number, pricekeyId: number) {
		return get<IPackageModifierGroupBE>(`/centers/${systemId}/Packages/${pricekeyId}/Modifiers`);
	}

	function footwears(query: IOffersQuery & IOfferQueryDate) {
		return get<IFootwearResponse>(`/centers/${query.systemId}/offers/${query.offerId}/shoes-socks-offer`, {
			query: buildQueryParams(query)
		});
	}

	function getShoesCategory(systemId: number) {
		return get<IShoesCategoryData>(`/centers/${systemId}/ShoesSize`);
	}

	function extras(query: IOffersQuery & IOfferQueryDate & Partial<IOfferQueryPagination>) {
		return get<IReservationExtra[]>(`/centers/${query.systemId}/offers/extras`, {
			query: buildQueryParams(query)
		});
	}

	function reservationCreate(systemId: number, data: IReservationRequest) {
		return post<IReservationLifetime, IResponseError<ReservationLifetimeError>>(
			`/centers/${systemId}/reservations/temporary-request/book-for-later`,
			{
				body: {
					...data,
					DateFrom: dateTimeKey(data.DateFrom)
				},
				throwExcluding: [
					(error) => {
						const errorCode = error?.data?.Error?.Code ?? "";

						return error.statusCode === 409 && ["QResourceNotAvailable", "UnlimitedConfigConflict", "BookingConflict"].includes(errorCode);
						// FIXME: Gestire il 503 (Conqueror offline), attendere user story dedicata come da accordi con Analisi
					}
				]
			}
		);
	}

	function reservationStatus(systemId: number, reservationKey: string) {
		return get<ReservationStatus, IResponseError<ReservationTimeoutError>>(`/centers/${systemId}/reservations/${reservationKey}/status`, {
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";

					if (error.statusCode === 409)
						return ["ReservationTimedOut"].includes(errorCode);

					return false;
				}
			]
		});
	}
	function reservationRenew(systemId: number, reservationKey: string) {
		return patch<IReservationLifetime, IResponseError<ReservationLifetimeError>>(`/centers/${systemId}/reservations/${reservationKey}/lifetime`, {
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";

					if (error.statusCode === 404)
						return errorCode === "ReservationNotFound";
					else if (error.statusCode === 409)
						return ["ReservationAlreadyConfirmed", "ReservationConflict", "ReservationNotTemporary"].includes(errorCode);

					return false;
				}
			]
		});
	}

	function reservationConfirm(systemId: number, reservationKey: string, data: IReservationConfirmData) {
		return post<IReservationConfirmResponse, IResponseError<ReservationConfirmError>>(`/centers/${systemId}/reservations/${reservationKey}/confirm`, {
			body: data,
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";

					switch (error.statusCode) {
						case 404:
							return ["ModifierNotFound", "ReservationExpired", "ReservationNotFound"].includes(errorCode);
						case 409:
							return ["ModifierChanged", "PriceAvailabilityChanged", "PriceValueChanged", "ReservationAlreadyConfirmed", "ReservationConflict", "ReservationNotTemporary"].includes(errorCode);
						case 502:
							return ["PaymentException", "PaymentProviderNotConfigured"].includes(errorCode);
						default:
							return false;
					}
				}
			]
		});
	}

	function reservationGuestConfirm(systemId: number, reservationKey: string, data: IReservationGuestConfirmData) {
		return post<IReservationConfirmResponse, IResponseError<ReservationConfirmError>>(`/centers/${systemId}/reservations/${reservationKey}/guest/confirm`, {
			body: data,
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";

					switch (error.statusCode) {
						case 404:
							return ["ModifierNotFound", "ReservationExpired", "ReservationNotFound"].includes(errorCode);
						case 409:
							return ["ModifierChanged", "PriceAvailabilityChanged", "PriceValueChanged", "ReservationAlreadyConfirmed", "ReservationConflict", "ReservationNotTemporary"].includes(errorCode);
						case 502:
							return ["PaymentException", "PaymentProviderNotConfigured"].includes(errorCode);
						default:
							return false;
					}
				}
			]
		});
	}

	function reservationConfirmPayment(systemId: number, reservationKey: string, data: IPaypalCompletionPayload | IPayAndConfirmResultPayload["providerData"]) {
		return put<null, IResponseError<ReservationError>>(`/centers/${systemId}/reservations/${reservationKey}/payment-confirm`, {
			body: { QueryParams: data },
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";
					switch (error.statusCode) {
						case 402:
							return errorCode === "PaymentCanceled";
						case 404:
							return ["OperationIdNotFound", "ReservationExpired"].includes(errorCode);
						case 409:
							return errorCode === "ReservationConflict";
						default:
							return false;
					}
				}
			]
		});
	}

	function rollback(systemId: number, reservationKey: string) {
		return del<null, IResponseError<ReservationError>>(`/centers/${systemId}/reservations/${reservationKey}/rollback`, {
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";
					switch (error.statusCode) {
						case 409:
							return errorCode === "ReservationConflict";
						default:
							return false;
					}
				}
			]
		});
	}

	function reservationSetEndFlow(systemId: number, reservationKey: string) {
		return patch(`/centers/${systemId}/reservations/${reservationKey}/SetEndFlow`);
	}

	function reservationDelete(systemId: number, reservationKey: string) {
		return del<any, IResponseError<ReservationDeleteError>>(`/centers/${systemId}/reservations/${reservationKey}`, {
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code;

					if (error.statusCode === 409)
						return errorCode === "PaymentInitiated" || errorCode === "ReservationConflict";
					return false;
				}
			]
		});
	}

	function recap(systemId: number, reservationKey: string) {
		return get<Blob>(`/centers/${systemId}/reservations/${reservationKey}/recap`, {
			responseType: "blob"
		});
	}

	function existsRecap(systemId: number, reservationKey: string) {
		return get(`/centers/${systemId}/reservations/${reservationKey}/exists-recap`, {
			throwExcluding: [
				{ statusCode: 204 },
				{ statusCode: 404 }
			]
		});
	}

	function getReservationOptions(systemId: number) {
		return get<IReservationOptions>(`/centers/${systemId}/ReservationOptions`);
	}

	function getWebOfferOptions(systemId: number, offerId: number) {
		return get<IWebOfferOptions>(`/centers/${systemId}/weboffers/${offerId}/options`);
	}

	function getReservationStatus(systemId: number, reservationKey: string, operationId: string) {
		return get<IReservationStatusResponse, IResponseError<ReservationStatusErrorCodes>>(`/centers/${systemId}/reservations/${reservationKey}/status/${operationId}`, {
			throwExcluding: [error => {
				return ((error.statusCode === 404)
					&& typeof error.data?.Error?.Code === "string"
					&& ReservationStatusErrors.includes(error.data.Error.Code));
			}]
		});
	}

	function saveBowlersOptions(systemId: number, reservationKey: string, Players: IReservationBowlerOptions[]) {
		return patch<IReservationBowlerOptions, IResponseError>(`/centers/${systemId}/reservations/${reservationKey}/players`, {
			body: {
				Players
			}
		});
	}

	return {
		offers,
		foodBeverageCategories,
		foodBeverage,
		getItemFoodBeverageModifiers,
		getPackageFoodBeverageModifiers,
		footwears,
		getShoesCategory,
		extras,
		reservationCreate,
		reservationStatus,
		reservationRenew,
		reservationConfirm,
		reservationGuestConfirm,
		reservationConfirmPayment,
		rollback,
		reservationSetEndFlow,
		reservationDelete,
		recap,
		existsRecap,
		getReservationOptions,
		getWebOfferOptions,
		getReservationStatus,
		saveBowlersOptions
	};
}
