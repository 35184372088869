<template>
	<div
		v-if="themeLoaded"
		class="policy-term"
	>
		<Layout>
			<h1
				v-if="data.Title"
				class="h1 page-title"
				v-html="data.Title"
			/>
			<div
				v-if="data.Content"
				class="page-content"
				v-html="data.Content"
			/>
		</Layout>
	</div>
</template>

<script lang="ts" src="./policyTerm.ts"></script>

<style lang="scss">
	.view.policy-term {
		.page-title {
			margin-bottom: 1rem;
		}
	}
</style>
