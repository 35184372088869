import PolicyTermPage from "@app-views/policyTerm/policyTerm.vue";
export const policyPage = "privacy-policy";
export const policyTermPage = "policy-term";

const policyTermRoutes = [
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})?/policy/:type",
		name: policyPage,
		component: PolicyTermPage
	},
	{
		path: "/:companyId(\\d+)/:systemId(\\d{4,})?/terms/:type",
		name: policyTermPage,
		component: PolicyTermPage
	}
];
export default policyTermRoutes;
