import { ISystemCurrency } from "@app-rest/systems.interfaces";
import useSystemStore from "@app-store/system";
import { computed, defineComponent, onMounted } from "vue";

const emptyCurrency: ISystemCurrency = {
	CurrencyID: "",
	Conversion: 0,
	DecDigits: 2,
	DecimalSep: ".",
	Description: "",
	RoundType: 0,
	RoundUnit: 0,
	Symbol: "",
	SymbolBeforeValue: true,
	ThousandSep: ","
};

export default defineComponent({
	name: "Price",
	props: {
		value: { type: Number, default: 0 },
		spinnerSize: { type: String, default: "xs" },
		round: { type: Boolean, default: false }
	},
	setup(props) {
		const loadingSystemCurrency = computed(() => {
			return useSystemStore().loadingCurrency;
		});
		const currency = computed(() => {
			return useSystemStore().currency ?? emptyCurrency;
		});
		const symbol = computed(() => {
			return currency.value.Symbol;
		});
		const symbolBefore = computed(() => {
			return currency.value.SymbolBeforeValue;
		});
		const thousands = computed(() => {
			let decimalCount = Math.abs(currency.value?.DecDigits ?? 2);
			if (isNaN(decimalCount)) decimalCount = 2;
			const amountParsed = Math.abs(Number(props.value) || 0).toString().split(".")[0];
			const integerPart = parseInt(amountParsed);
			const integerPartString = integerPart.toString();
			const hasThousands = integerPartString.length > 3 ? integerPartString.length % 3 : 0;
			return hasThousands ? integerPartString.substr(0, hasThousands) : "";
		});
		const thousandsSeparator = computed(() => {
			return currency.value.ThousandSep ?? "";
		});
		const hasDecimal = computed(() => {
			return Boolean(currency.value?.DecDigits ?? 0);
		});
		const decimalSeparator = computed(() => {
			return currency.value.DecimalSep ?? "";
		});
		const decimalPart = computed(() => {
			const amount = props.value;
			let decimalCount = Math.abs(currency.value?.DecDigits ?? 2);
			if (isNaN(decimalCount)) decimalCount = 2;
			if (!decimalCount) return "";
			const amountParsed = Math.abs(Number(amount) || 0).toString().split(".")[0];
			const integerPart = parseInt(amountParsed);
			return Math.abs(amount - integerPart).toFixed(decimalCount).toString().replace("0.", "");
		});
		const parseIntegerPart = () => {
			const amountParsed = Math.abs(Number(props.value) || 0);
			let integerPart = "";
			if (props.round)
				integerPart = amountParsed.toFixed(currency.value.DecDigits);
			else integerPart = amountParsed.toString().split(".")[0];
			return parseInt(integerPart);
		};
		const integerPart = computed(() => {
			const integerPart = parseIntegerPart();
			const integerPartString = integerPart.toString();
			const hasThousands = integerPartString.length > 3 ? integerPartString.length % 3 : 0;
			return integerPartString
				.substr(hasThousands)
				.replace(/(\d{3})(?=\d)/g, "$1");
		});
		onMounted(() => {
			useSystemStore().ensureCurrency();
		});
		return {
			loadingSystemCurrency,
			currency,
			symbol,
			symbolBefore,
			thousands,
			thousandsSeparator,
			hasDecimal,
			decimalSeparator,
			decimalPart,
			parseIntegerPart,
			integerPart
		};
	}
});
