import useCompanyRestClient from "@app-rest/company";
import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import useLangStore from "@app-store/lang";
import useSystemStore from "@app-store/system";
import { EventAI, trackTrace, trackUserEvent, trackView } from "@app-utilities/app-insights";
import { isBookingPage, isErrorPage } from "@app-utilities/const";
import type { NetworkInformation } from "@app-utilities/interfaces";
import { resolveCompanyFirstSystem } from "@app-utilities/system";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { notFoundFallbackViewName } from ".";
import { isSkippable, redirectToMaintenanceMode } from "./helpers";
import { maintenanceMode } from "./routes/base";
import { home } from "./routes/reservations";

export default async(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
	const AppStore = useAppStore();
	const LangStore = useLangStore();
	const SystemStore = useSystemStore();
	const companyStore = useCompanyStore();

	if (AppStore.azurePopupWindowIsOpen)
		return Promise.resolve();

	async function redirectToNotFound(reason: string, from: RouteLocationNormalized) {
		await LangStore.ensureLanguage();
		trackUserEvent(EventAI.RedirectToNotFound, { reason, from });
		return next({
			name: "not-found",
			params: to.params,
			query: to.query
		});
	}

	if (to.name === notFoundFallbackViewName)
		trackUserEvent(EventAI.RedirectToNotFound, { reason: "vue router not found fallback" });

	if (to.name !== "root" && to.path === from.path)
		return next();

	if (to.name === "root" && AppStore.isPWASession() && AppStore.defaultPWABaseRoute) {
		return next({
			name: "root-company-system",
			params: {
				...to.params,
				...AppStore.defaultPWABaseRoute
			},
			query: to.query
		});
	}

	if (to.name && (isSkippable(to, next) || isErrorPage(to.name))) {
		await LangStore.ensureLanguage();
		return next();
	}

	const basicData: Promise<any>[] = [];

	if ("companyId" in to.params) {
		const companyId = +to.params.companyId;
		if (!companyStore.Id || companyStore.Id !== companyId) {
			const companyRestClient = useCompanyRestClient();
			const response = await companyRestClient.info(companyId);
			companyStore.setCompanyData(response.data);
		}

		trackTrace({
			message: "Prompt: Company info loaded",
			severityLevel: SeverityLevel.Information
		});

		let systemId: number | null = null;

		if (!("systemId" in to.params)) {
			const resolvedSystem = await resolveCompanyFirstSystem(companyId);

			if (!resolvedSystem || isNaN(resolvedSystem))
				return redirectToNotFound("cannot resolve system", from);

			systemId = resolvedSystem;
			if (!systemId)
				return redirectToNotFound("resolved systemId is falsy", from);

			const nextRoute = to.name && !to.name.toString().startsWith("root") ? to.name : home;
			return next({
				name: nextRoute,
				query: to.query,
				params: { ...to.params, systemId: systemId + "" }
			});
		} else
			systemId = +to.params.systemId;

		const pair = { Id: systemId, CompanyId: companyId };
		const { ensureSystemCompanyPair } = await import("@app-utilities/system");
		const ensureCompanyPairResult = await ensureSystemCompanyPair(pair, true);

		if (!ensureCompanyPairResult.success) {
			const message = ensureCompanyPairResult.details;
			return redirectToNotFound(message, from);
		}

		if (!SystemStore.functionalities)
			return redirectToNotFound("Cannot retrieve functionalities", from);

		if (SystemStore.info) {
			const companyIdFromSystemInfo = SystemStore.info.CompanyId;
			const systemId = SystemStore.info.Id;
			if (!systemId || !companyIdFromSystemInfo)
				return redirectToNotFound("missing systemId or companyId from system store", from);

			if (companyId && companyIdFromSystemInfo && companyIdFromSystemInfo !== companyId)
				return redirectToNotFound("companyId param doesn't match companyId in system store", from);

			trackTrace({
				message: "Prompt: System info loaded",
				severityLevel: SeverityLevel.Information
			});

			const { ensureWebsiteData } = await import("@app-utilities/system");
			basicData.push(ensureWebsiteData());
			basicData.push((async() => {
				if (!AppStore.themeLoaded) {
					await AppStore.syncTheme({ companyId, systemId, previewUrl: (to.query.previewUrl ?? "") as string });
					AppStore.setThemeLoaded();

					trackTrace({
						message: "Prompt: Theme loaded",
						severityLevel: SeverityLevel.Information
					}, {
						...(to.query.previewUrl && { previewUrl: to.query.previewUrl })
					});

					// FIXME: NetworkInformation API is experimental and types are not available - update when API is stable (TS: #47495)
					const appReadyEventData: { networkInformation?: NetworkInformation } = {};
					const extendedNavigator = navigator as Navigator & { connection?: NetworkInformation };
					const navigatorConnection = extendedNavigator.connection;

					if (navigatorConnection) {
						appReadyEventData.networkInformation = {
							downlink: navigatorConnection.downlink ?? null,
							downlinkMax: navigatorConnection.downlinkMax ?? null,
							effectiveType: navigatorConnection.effectiveType ?? null,
							rtt: navigatorConnection.rtt ?? null,
							saveData: navigatorConnection.saveData ?? null,
							type: navigatorConnection.type ?? null
						};
					}

					await SystemStore.syncMaintenanceModeActive();
					trackUserEvent(EventAI.AppReady, appReadyEventData);
				}
				return Promise.resolve();
			})());
		}
	}

	await Promise.all(basicData);
	await LangStore.ensureLanguage();
	if (SystemStore.maintenanceMode && to.name !== maintenanceMode)
		return redirectToMaintenanceMode(next);

	let preventNext = false;
	if (isBookingPage(to.name)) {
		const { processBookingRoute } = await import("@app-store/reservation-flux");
		const result = await processBookingRoute(to, from);
		preventNext = result?.preventNext ?? false;
	}

	if (to.name)
		trackView(to.name.toString(), "start", to.fullPath);

	if (!preventNext)
		next();
};
