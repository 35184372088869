<template>
	<div class="cart-summary">
		<div
			v-for="category in categories"
			:key="category"
			class="cart-category"
			:category="category"
		>
			<div class="row-cart cart-category__row-header">
				<div class="item-tools">
					<NavButton
						class="prev"
						variant="outline-secondary"
						data-test="nav-button-prev-cart-category"
						@click="onBackClick(category)"
					/>
				</div>
				<div class="item-description">
					{{ getLinkLabel(category) }}
				</div>
				<div class="item-total-price">
					<Price
						:value="getTotalInCategory(category)"
						:system-id="systemId"
					/>
				</div>
			</div>
			<div
				v-for="item in getItemsInCategory(category)"
				:key="`item-${item.ItemCompoundKey ?? item.ItemId}`"
				class="cart-category-item"
			>
				<div
					class="row-cart cart-category-item__details"
					:class="{
						'has-quantifier': hasQuantifier(category),
						'has-close': canBeRemoved(category),
						'has-modifiers': item.Modifiers && item.Modifiers.length > 0
					}"
				>
					<div class="item-tools">
						<q-button
							v-if="canBeRemoved(category)"
							variant="remove"
							@click="removeItem(item)"
						>
							<i class="bi bi-x" />
						</q-button>
					</div>
					<div class="item-description">
						{{ item.Title }}
					</div>
					<div
						class="item-quantity"
						:class="{ 'with-quantifier': hasQuantifier(category) }"
					>
						<Quantifier
							v-if="hasQuantifier(category)"
							ref="quantifiers"
							:sub-disabled="subButtonDisabled(item)"
							:label-sub="$t('label_sub')"
							:label-plus="$t('label_plus')"
							@click-sub="onSubClick(item)"
							@click-add="onAddClick(item)"
						>
							<template #btn-sub-aria-label>
								{{ $t("label_sub") }} {{ item.Title }}
							</template>
							<template #btn-add-aria-label>
								{{ $t("label_plus") }} {{ item.Title }}
							</template>
							<template #quantity-label>
								{{ item.Quantity }}
							</template>
						</Quantifier>
						<template v-else>
							<template v-if="isBowlingCategory(category)">
								{{ playersLabel }}
							</template>
							<template v-else-if="isFootwears(category)">
								{{ getFootwearLabel(item.ItemId) }}
							</template>
							<template v-else>
								{{ item.Quantity }}
							</template>
						</template>
					</div>
					<div class="item-total-price">
						<Price
							:value="item.UnitPrice * item.Quantity"
							:system-id="systemId"
						/>
					</div>
				</div>

				<div
					v-if="item.Modifiers && item.Modifiers.length > 0"
					class="row-cart cart-category-item__modifiers"
				>
					<div
						v-if="item.Modifiers"
						class="cart-item__modifiers"
					>
						<span
							v-for="(mod, i) in item.Modifiers"
							:key="mod.Id"
						>
							{{ mod.Name }}<span v-if="i < (item.Modifiers.length - 1)">,</span>
						</span>
					</div>
				</div>
				<div
					v-if="item.Note"
					class="row-cart cart-category-item__note"
				>
					<p class="cart-item__note">
						{{ item.Note }}
					</p>
				</div>
			</div>
		</div>
		<div
			v-if="showTotal"
			class="row-cart cart-summary__row-footer"
		>
			<div class="total-label">
				{{ $t("label_total_wo_taxes") }}
			</div>
			<Price
				class="total-in-cart"
				:value="totalWithoutTaxes"
				:system-id="systemId"
			/>
		</div>
	</div>
</template>
<script lang="ts" src="./cart-summary.ts"></script>
<style lang="scss" src="./cart-summary.scss"></style>
