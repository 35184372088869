<template>
	<q-overlay
		:show="flags.loading"
		rounded="sm"
		opacity="0"
	>
		<b-navbar class="footer-menu">
			<b-navbar-nav
				v-if="hasMinimumData"
				class="footer-menu-nav"
			>
				<b-nav-item
					v-for="menuVoice in footerMenuVoices"
					:key="menuVoice.label"
					:to="!menuVoice.isExternal ? menuVoice.url : undefined"
					target="_blank"
				>
					{{ menuVoice.label }}
				</b-nav-item>
			</b-navbar-nav>
		</b-navbar>
	</q-overlay>
</template>

<script lang="ts" src="./footer-menu"></script>
