<template>
	<span
		class="price"
		:class="{ loading: loadingSystemCurrency }"
	>
		<Spinner
			v-if="loadingSystemCurrency"
			:size="spinnerSize"
			inline
		/>
		<template v-else>
			<span
				v-if="symbolBefore"
				class="symbol"
			>{{ symbol }}</span>
			<span class="thousands">{{ thousands }}</span>
			<span
				v-if="thousands"
				class="thousands separator"
			>{{ thousandsSeparator }}</span>
			<span class="integer">{{ integerPart }}</span>
			<span
				v-if="hasDecimal"
				class="decimal separator"
			>{{ decimalSeparator }}</span>
			<span
				v-if="hasDecimal"
				class="decimal"
			>{{ decimalPart }}</span>
			<span
				v-if="!symbolBefore"
				class="symbol"
			>{{ symbol }}</span>
		</template>
	</span>
</template>
<script lang="ts" src="./price.ts"></script>
<style lang="scss">
.price {
	& > span {
		&:empty {
			display: none;
		}
	}
}
</style>
